<template>
  <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
    <div class="property-item rounded overflow-hidden">
      <div class="position-relative overflow-hidden">
        <a href=""><img class="img-fluid" :src="propertyImage" alt="" /></a>
        <div
          class="bg-primary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3"
        >
          {{ category }}
        </div>
        <div
          class="bg-white rounded-top text-primary position-absolute start-0 bottom-0 mx-4 pt-1 px-3"
        >
          {{ propertyType }}
        </div>
      </div>
      <div class="p-4 pb-0">
        <h5 class="text-primary mb-3">RWF {{ propertyAmount }}</h5>
        <a class="d-block h5 mb-2" href="">{{ propertyTitle }}</a>
        <p>
          <i class="fa fa-map-marker-alt text-primary me-2"></i
          >{{ streetNumber }}
        </p>
      </div>
      <div class="d-flex border-top">
        <small class="flex-fill text-center border-end py-2"
          ><i class="fa fa-ruler-combined text-primary me-2"></i
          >{{ propertySize }} Sqft</small
        >
        <small class="flex-fill text-center border-end py-2"
          ><i class="fa fa-bed text-primary me-2"></i>{{ bedRoom }} Bed</small
        >
        <small class="flex-fill text-center py-2"
          ><i class="fa fa-bath text-primary me-2"></i
          >{{ bathRoom }} Bath</small
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    propertyImage: {
      type: String,
      default: 'img/property-1.jpg'
    },
    category: {
      type: String,
      default: 'For Sell'
    },
    propertyType: {
      type: String,
      default: 'Appartment'
    },
    propertyAmount: {
      type: String,
      default: '500,000'
    },
    propertyTitle: {
      type: String,
      default: 'Apartment House For Sell'
    },
    streetNumber: {
      type: String,
      default: 'KG219 St 34, Kigali RWANDA'
    },
    propertySize: {
      type: String,
      default: '100'
    },
    bedRoom: {
      type: String,
      default: '3'
    },
    bathRoom: {
      type: String,
      default: '1'
    }
  },
  data () {
    return {
      browserPropertyAll: true
    }
  }
}
</script>
