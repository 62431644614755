<template>
  <div class="container-xxl bg-white p-0">

    <!-- Navbar Start -->
    <HeaderItem />
    <!-- Navbar End -->

    <!-- Header Start -->
    <br><br><br>
    <div class="container-fluid header bg-white p-0">
      <div class="row g-0 align-items-center flex-column-reverse flex-md-row">
        <div class="col-md-6 p-5 mt-lg-5">
          <p class="display-5 animated fadeIn mb-4">
            <span class="text-primary">Kaph Group</span> Your Destination for Finding Your Dream Home!
          </p>
          <p class="animated fadeIn mb-4 pb-2">
            Looking for your ideal home for sale or rent? Explore our extensive listings of properties tailored to meet your unique needs.
          </p>
          <a href="" class="btn btn-primary py-3 px-5 me-3 animated fadeIn"
            >Get Started</a
          >
        </div>
        <div class="col-md-6 animated fadeIn">
          <div class="owl-carousel header-carousel">
            <div class="owl-carousel-item">
              <img class="img-fluid" src="img/carousel-1.jpg" alt="" />
            </div>
            <div class="owl-carousel-item">
              <img class="img-fluid" src="img/carousel-2.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Header End -->

    <!-- Search Start -->
    <SearchItem />
    <!-- Search End -->

    <!-- Property List Start -->
    <PropertyListing />
    <!-- Property List End -->

    <!-- Call to Action Start -->
    <div class="container-xxl py-5">
      <div class="container">
        <div class="bg-light rounded p-3">
          <div
            class="bg-white rounded p-4"
            style="border: 1px dashed rgba(0, 185, 142, 0.3)"
          >
            <div class="row g-5 align-items-center">
              <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                <img
                  class="img-fluid rounded w-100"
                  src="img/call-to-action.jpg"
                  alt=""
                />
              </div>
              <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                <div class="mb-4">
                  <h1 class="mb-3">Contact With Our Certified Agent</h1>
                  <p>
                    Eirmod sed ipsum dolor sit rebum magna erat. Tempor lorem
                    kasd vero ipsum sit sit diam justo sed vero dolor duo.
                  </p>
                </div>
                <a href="" class="btn btn-primary py-3 px-4 me-2"
                  ><i class="fa fa-phone-alt me-2"></i>Make A Call</a
                >
                <a href="" class="btn btn-dark py-3 px-4"
                  ><i class="fa fa-calendar-alt me-2"></i>Get Appoinment</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Call to Action End -->

    <!-- Team Start -->
    <div class="container-xxl py-5">
      <div class="container">
        <div
          class="text-center mx-auto mb-5 wow fadeInUp"
          data-wow-delay="0.1s"
          style="max-width: 600px"
        >
          <h1 class="mb-3">Property Agents</h1>
          <p>
            Eirmod sed ipsum dolor sit rebum labore magna erat. Tempor ut dolore
            lorem kasd vero ipsum sit eirmod sit. Ipsum diam justo sed rebum
            vero dolor duo.
          </p>
        </div>
        <div class="row g-4">
          <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div class="team-item rounded overflow-hidden">
              <div class="position-relative">
                <img class="img-fluid" src="img/team-1.jpg" alt="" />
                <div
                  class="position-absolute start-50 top-100 translate-middle d-flex align-items-center"
                >
                  <a class="btn btn-square mx-1" href=""
                    ><i class="fab fa-facebook-f"></i
                  ></a>
                  <a class="btn btn-square mx-1" href=""
                    ><i class="fab fa-twitter"></i
                  ></a>
                  <a class="btn btn-square mx-1" href=""
                    ><i class="fab fa-instagram"></i
                  ></a>
                </div>
              </div>
              <div class="text-center p-4 mt-3">
                <h5 class="fw-bold mb-0">Full Name</h5>
                <small>Designation</small>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div class="team-item rounded overflow-hidden">
              <div class="position-relative">
                <img class="img-fluid" src="img/team-2.jpg" alt="" />
                <div
                  class="position-absolute start-50 top-100 translate-middle d-flex align-items-center"
                >
                  <a class="btn btn-square mx-1" href=""
                    ><i class="fab fa-facebook-f"></i
                  ></a>
                  <a class="btn btn-square mx-1" href=""
                    ><i class="fab fa-twitter"></i
                  ></a>
                  <a class="btn btn-square mx-1" href=""
                    ><i class="fab fa-instagram"></i
                  ></a>
                </div>
              </div>
              <div class="text-center p-4 mt-3">
                <h5 class="fw-bold mb-0">Full Name</h5>
                <small>Designation</small>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
            <div class="team-item rounded overflow-hidden">
              <div class="position-relative">
                <img class="img-fluid" src="img/team-3.jpg" alt="" />
                <div
                  class="position-absolute start-50 top-100 translate-middle d-flex align-items-center"
                >
                  <a class="btn btn-square mx-1" href=""
                    ><i class="fab fa-facebook-f"></i
                  ></a>
                  <a class="btn btn-square mx-1" href=""
                    ><i class="fab fa-twitter"></i
                  ></a>
                  <a class="btn btn-square mx-1" href=""
                    ><i class="fab fa-instagram"></i
                  ></a>
                </div>
              </div>
              <div class="text-center p-4 mt-3">
                <h5 class="fw-bold mb-0">Full Name</h5>
                <small>Designation</small>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
            <div class="team-item rounded overflow-hidden">
              <div class="position-relative">
                <img class="img-fluid" src="img/team-4.jpg" alt="" />
                <div
                  class="position-absolute start-50 top-100 translate-middle d-flex align-items-center"
                >
                  <a class="btn btn-square mx-1" href=""
                    ><i class="fab fa-facebook-f"></i
                  ></a>
                  <a class="btn btn-square mx-1" href=""
                    ><i class="fab fa-twitter"></i
                  ></a>
                  <a class="btn btn-square mx-1" href=""
                    ><i class="fab fa-instagram"></i
                  ></a>
                </div>
              </div>
              <div class="text-center p-4 mt-3">
                <h5 class="fw-bold mb-0">Full Name</h5>
                <small>Designation</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Team End -->

    <!-- Footer Start -->
    <FooterItem />
    <!-- Footer End -->

    <!-- Back to Top -->
    <a href="#" class="btn btn-lg btn-primary btn-lg-square back-to-top"
      ><i class="bi bi-arrow-up"></i
    ></a>
  </div>
</template>
<script>
import HeaderItem from '../components/HeaderItem.vue'
import FooterItem from '../components/FooterItem.vue'
import SearchItem from '../components/SearchItem.vue'
import PropertyListing from '../components/PropertyListing.vue'
export default {
  components: {
    HeaderItem,
    FooterItem,
    SearchItem,
    PropertyListing
  }
}
</script>
