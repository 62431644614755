<template>
  <div class="container-xxl py-5">
    <div class="container">
      <div class="row g-0 gx-5 align-items-end">
        <div class="col-lg-6">
          <div
            class="text-start mx-auto mb-5 wow slideInLeft"
            data-wow-delay="0.1s"
          >
            <h1 class="mb-3">Property Listing</h1>
            <p>
              "Our platform provides detailed descriptions, vibrant images, and
              key features for informed decision-making. Find your dream home
              effortlessly and begin your search today!"
            </p>
          </div>
        </div>
        <div
          class="col-lg-6 text-start text-lg-end wow slideInRight"
          data-wow-delay="0.1s"
        >
          <ul class="nav nav-pills d-inline-flex justify-content-end mb-5">
            <li class="nav-item me-2">
              <a
                class="btn btn-outline-primary active"
                data-bs-toggle="pill"
                href="#tab-1"
                >Featured</a
              >
            </li>
            <li class="nav-item me-2">
              <a
                class="btn btn-outline-primary"
                data-bs-toggle="pill"
                href="#tab-2"
                >For Sell</a
              >
            </li>
            <li class="nav-item me-0">
              <a
                class="btn btn-outline-primary"
                data-bs-toggle="pill"
                href="#tab-3"
                >For Rent</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="tab-content">
        <div id="tab-1" class="tab-pane fade show p-0 active">
          <div class="row g-4">
            <PropertyListingFeatures
            v-for="(feature, key) in features"
            :key="key"
            :propertyTitle="feature.propertyTitle"
            :propertyType="feature.propertyType"
            :category="feature.category"
            :bathRoom="feature.bathRoom"
            :bedRoom="feature.bedRoom"
            :propertyImage="feature.propertyImage"
            :propertyAmount="feature.propertyAmount"
            :propertySize="feature.propertySize"
            :streetNumber="feature.streetNumber" />
            <div class="col-12 text-center wow fadeInUp" data-wow-delay="0.1s" v-if="browserPropertyAll">
              <a class="btn btn-primary py-3 px-5" href=""
                >Browse More Property</a
              >
            </div>
          </div>
        </div>
        <div id="tab-2" class="tab-pane fade show p-0">
          <div class="row g-4">
           <PropertyListingFeatures
           v-for="(feature, key) in sells"
            :key="key"
            :propertyTitle="feature.propertyTitle"
            :propertyType="feature.propertyType"
            :category="feature.category"
            :bathRoom="feature.bathRoom"
            :bedRoom="feature.bedRoom"
            :propertyImage="feature.propertyImage"
            :propertyAmount="feature.propertyAmount"
            :propertySize="feature.propertySize"
            :streetNumber="feature.streetNumber" />
            <div class="col-12 text-center">
              <a class="btn btn-primary py-3 px-5" href=""
                >Browse More Property</a
              >
            </div>
          </div>
        </div>
        <div id="tab-3" class="tab-pane fade show p-0">
          <div class="row g-4">
            <PropertyListingFeatures
           v-for="(feature, key) in rents"
            :key="key"
            :propertyTitle="feature.propertyTitle"
            :propertyType="feature.propertyType"
            :category="feature.category"
            :bathRoom="feature.bathRoom"
            :bedRoom="feature.bedRoom"
            :propertyImage="feature.propertyImage"
            :propertyAmount="feature.propertyAmount"
            :propertySize="feature.propertySize"
            :streetNumber="feature.streetNumber" />
            <div class="col-12 text-center">
              <a class="btn btn-primary py-3 px-5" href=""
                >Browse More Property</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PropertyListingFeatures from '../components/propertyItem/PropertyListingFeatures.vue'
export default {
  components: {
    PropertyListingFeatures
  },
  data () {
    return {
      features: [
        {
          propertyTitle: 'Appartment For Sell'
        },
        {
          propertyTitle: 'Appartment For Rent',
          propertyType: 'For Rent',
          propertyAmount: '100,000'
        }
      ],
      sells: [
        {
          propertyTitle: 'Appartment For Sell',
          propertyType: 'For Sell',
          propertyAmount: '100,000'
        }
      ],
      rents: [
        {
          propertyTitle: 'Appartment For Rent',
          propertyType: 'For Rent',
          propertyAmount: '50,000'
        }
      ]
    }
  },
  methods: {
    async propertyListingFeatures () {}
  }
}
</script>
