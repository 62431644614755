import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/property-list',
    name: 'PropertyList',
    component: () => import('../views/PropertyList.vue')
  },
  {
    path: '/property-type',
    name: 'PropertyType',
    component: () => import('../views/PropertyType.vue')
  },
  {
    path: '/property-agent',
    name: 'PropertyAgent',
    component: () => import('../views/PropertyAgent.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/ContactView.vue')
  },
  {
    path: '/create-property',
    name: 'CreateProperty',
    component: () => import('../views/CreateProperty.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
